import React, { useState } from "react"
import Header from "../components/Header"
import "../styles/app.css"
import Helmet from "react-helmet"
import Favicon from "../imgs/favicon.png"
import { Link } from "gatsby"
import BgImg from "../imgs/sofort-online-payment-provider.jpg"
import ImgEn from "../imgs/en.png"
import ImgDe from "../imgs/de.png"
import { Subtitle } from "../components/index"

export default function HomeDe() {
  const [isHamburgerClicked, setIsHamburgerClicked] = useState(false)

  function handleNavBtnClick() {
    setIsHamburgerClicked(prevState => {
      return !prevState
    })
  }

  const mobileNavBarStyle = {
    left: `${isHamburgerClicked ? "10px" : "10000px"}`,
  }

  return (
    <div className="homepage">
      <Helmet>
        <title>
          Sofort Casinos – Online Casinos That Accept Sofort 2022 - 404 Page
        </title>
        <meta
          name="title"
          content="Sofort Casinos – Online Casinos That Accept Sofort 2022 - 404 Page"
        />
        <meta
          name="description"
          content="Sofort Online Casinos - 404 Page. Check out online casinos accepting Sofort payment method in 2022 for both deposits & withdrawals. Learn more about Sofort."
        />
        <link rel="shortcut icon" type="image/x-icon" href={Favicon} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
        <link
          rel="alternate"
          href="https://www.getsofort.com/de"
          hreflang="de"
        />
        <link rel="alternate" href="https://www.getsofort.com" hreflang="en" />
        <link
          rel="alternate"
          href="https://www.getsofort.com"
          hreflang="x-default"
        />
      </Helmet>
      <Header />
      <div className="navigation">
        <div
          role="presentation"
          className="hamburger-icon"
          onClick={handleNavBtnClick}
          onKeyDown={handleNavBtnClick}
        >
          <div className="hamburger-icon-line"></div>
          <div className="hamburger-icon-line"></div>
          <div className="hamburger-icon-line"></div>
        </div>
        <ul style={mobileNavBarStyle}>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/#about-sofort">About Sofort</Link>
          </li>
          <li>
            <Link to="/#online-casinos">Online Casinos</Link>
          </li>
          <li>
            <Link to="/#deposit">Deposit</Link>
          </li>
          <li>
            <Link to="/#cashouts">Cashouts</Link>
          </li>
          <li>
            <Link to="/#faq">FAQ</Link>
          </li>
          <li>
            <Link to="/blog">Blog</Link>
          </li>
        </ul>
      </div>
      <div className="langs">
        <Link to="/">
          <img src={ImgEn} alt="English" />
        </Link>
        <Link to="/de">
          <img src={ImgDe} alt="Deutsch" />
        </Link>
      </div>
      <div className="home" id="home">
        <div className="home-top">
          <img
            src={BgImg}
            alt="sofort-online-payment-provider"
            className="home-image"
          />
          <h1
            className="centered-in-image"
            style={{ textTransform: "uppercase" }}
          >
            Sofort Online Casinos
          </h1>
        </div>
      </div>
      <Subtitle>Oops - 404 Page!</Subtitle>
      <p>The page you are trying to view does not exist.</p>
      <div className="footer">
        © Copyright 2022. All Rights Reserved by GetSofort
      </div>
    </div>
  )
}
